<template>
    <div class="review-list">
        <list ref="list" :apiList="apiList" :fs="reviewFields" api="reviews" model="review" :showDelete="showDelete">
            <template slot="list-action" slot-scope="{ item }">
                <md-button @click="startReply(item)" class="md-icon-button">
                    <md-icon>reply</md-icon>
                </md-button>
            </template>
        </list>
        <ReviewReplyModal ref="ReviewReplyModal"></ReviewReplyModal>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import List from "@/components/List";
import ReviewReplyModal from "@/components/Review/ReviewReplyModal";
import { reviewFields } from "@/config";

export default {
    components: {
        List,
        ReviewReplyModal
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        }),
        apiList() {
            if (this.user.role == "vendor") {
                return `reviews?vendor_id=${this.user._id}`;
            }
            if (this.user.role == "staff") {
                return `reviews?vendor_id=${this.user.staff_vendor_id}`;
            }

            return "reviews";
        },
        reviewFields() {
            // only manager can active
            if (this.hasRole("manager")) {
                return reviewFields;
            }
            return reviewFields.filter(field => field.data != "active");
        },
        showDelete() {
            return this.hasRole("manager");
        }
    },
    methods: {
        async startReply(item) {
            let r = await this.$refs["ReviewReplyModal"].process(item._id);
            if (r.action == "success") {
                this.$refs.list.loadItems();
            }
        }
    },

    created() {}
};
</script>
<style lang="less">
.review-list {
    .md-table-row {
        height: auto;
        // .md-table-head,.md-table-cell{
        //   height: auto;

        //    &:nth-child(1) {
        //     width:200px;
        //       white-space: normal;
        //   }
        //   &:nth-child(5) {
        //     width:500px;
        //       white-space: normal;
        //   }
        //    &:nth-child(7) {
        //     width:130px;
        //       // white-space: normal;
        //   }
        // }
    }
}
</style>
