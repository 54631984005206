<template>
    <div class="common-list">
        <md-table-card>
            <form @submit.prevent="loadItems(true)" action>
                <div class="list-toolbar">
                    <h1 class="md-title" style="flex:inherit">{{ title || api | titlecase }}</h1>
                    <router-link :to="addLink" class="md-icon-button md-raised md-dense md-primary" tag="md-button" v-if="addLink">
                        <md-icon>add</md-icon>
                    </router-link>
                    <div style="flex:1"></div>

                    <slot :params="params" name="list-search"></slot>

                    <div class="title-select" style="border-bottom:0px;" v-if="showSearch">
                        <md-button class="md-icon-button">
                            <md-icon>search</md-icon>
                        </md-button>
                        <md-input-container md-inline style="width:150px">
                            <md-input placeholder="Search..." v-model="search"></md-input>
                        </md-input-container>
                    </div>

                    <div class="title-select">
                        <md-button class="md-primary md-raised" type="submit">Submit</md-button>
                    </div>
                </div>
            </form>
            <md-table @sort="handleSort">
                <md-table-header>
                    <md-table-row>
                        <md-table-head v-if="childrenfields.length"></md-table-head>
                        <md-table-head :key="ix" :md-sort-by="field.data" v-for="(field, ix) in usedfields">{{ field.heading }}</md-table-head>
                        <md-table-head v-if="showAction">Action</md-table-head>
                    </md-table-row>
                </md-table-header>
                <md-table-body>
                    <template v-for="(item, ix) in items">
                        <md-table-row :class="'c-' + getModel(item, classKey)" :key="ix" :md-item="item">
                            <md-table-cell v-if="childrenfields.length">
                                <md-button v-if="showExpandButton(item)" @click="setItemExpand(item)" class="md-icon-button">
                                    <md-icon v-if="!item.expand">add</md-icon>
                                    <md-icon v-if="item.expand">remove</md-icon>
                                </md-button>
                            </md-table-cell>
                            <md-table-cell :key="fix" v-for="(field, fix) in usedfields">
                                <slot :name="field.data" :model="item" :f="field">
                                    <list-field :f="field" :model="item" @update="updateItem"></list-field>
                                </slot>
                            </md-table-cell>
                            <md-table-cell v-if="showAction">
                                <div style="display:flex;align-items:center">
                                    <slot :item="item" name="list-action"></slot>
                                    <router-link :to="editLink + '/' + item._id" class="md-icon-button" tag="md-button" v-if="editLink">
                                        <md-icon>edit</md-icon>
                                    </router-link>
                                    <md-button @click="showDeleteConfirm(item)" class="md-icon-button" v-if="showDelete">
                                        <md-icon>delete</md-icon>
                                    </md-button>
                                </div>
                            </md-table-cell>
                        </md-table-row>
                        <template v-if="item.expand">
                            <md-table-row :key="'cix' + cix + '' + ix" :md-item="item" class="sub-table-row" v-for="(c, cix) in childrenfields">
                                <md-table @sort="handleSort">
                                    <md-table-header>
                                        <md-table-row>
                                            <md-table-head :key="chix" :md-sort-by="c.data + '.' + child.data" v-for="(child, chix) in c.children">{{ child.heading }}</md-table-head>
                                        </md-table-row>
                                    </md-table-header>
                                    <md-table-body>
                                        <md-table-row :key="cix" v-for="(citem, cix) in item[c.data]">
                                            <md-table-cell :key="chix" v-for="(child, chix) in c.children">
                                                <slot :name="child.data" :model="citem" :f="child">
                                                    <list-field :f="child" :model="citem" @update="updateItem"></list-field>
                                                </slot>
                                            </md-table-cell>
                                            <md-button @click="showDeleteConfirm(citem)" class="md-icon-button" v-if="c.showDelete">
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                        </md-table-row>
                                    </md-table-body>
                                </md-table>
                            </md-table-row>
                        </template>
                    </template>
                </md-table-body>
                <slot :items="items" name="list-bottom"></slot>
            </md-table>
            <md-table-pagination
                :md-page="page.pageNo"
                :md-page-options="[5, 10, 20, 50]"
                :md-size="page.pageSize"
                :md-total="page.total"
                @pagination="onPagination"
                md-label="Rows"
                md-separator="of"
                v-if="showPage"
            ></md-table-pagination>
        </md-table-card>
        <confirm @close="deleteConfirmed" ref="deleteConfirm"></confirm>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Confirm from "@/components/modal/Confirm";
import ListField from "@/components/ListField";
export default {
    props: {
        fs: { type: Array },
        api: { type: String },
        apiList: { type: String, default: "" },
        apiUpdate: { type: String, default: "" },
        apiRemove: { type: String, default: "" },

        addLink: { type: String, default: "" },
        editLink: { type: String, default: "" },

        showAction: { type: Boolean, default: true },
        showDelete: { type: Boolean, default: true },
        showSearch: { type: Boolean, default: true },
        showPage: { type: Boolean, default: true },

        defaultExpand: { type: Boolean, default: false },

        title: { type: String, default: "" },
        classKey: { type: String },

        beforeDelete: { type: Function, default: null }
    },
    components: {
        Confirm,
        ListField
    },
    data() {
        return {
            page: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            selectedItem: {},
            items: [],
            search: "",
            sort: undefined,
            params: {}
        };
    },
    computed: {
        ...mapGetters({
            getModel: "getModel"
        }),
        showExpandButton() {
            return item => {
                return true;
                // let data = this.childrenfields.length && this.childrenfields[0].data;
                // if (data) {
                //     return item[data] && item[data].length;
                // }
            };
        },
        usedfields() {
            return this.fs.filter(field => field.type != "children").filter(field => !field.when || field.when == "list");
        },
        childrenfields() {
            return this.fs.filter(field => field.type == "children").filter(field => !field.when || field.when == "list");
        },
        parseApi() {
            return api => {
                let match = api.match(/(.*?):(get|post|put|delete)/);
                let url = (match && match[1]) || api;
                let option = match && match[2];
                return { url, option };
            };
        },
        _apiList() {
            let url = this.apiList || this.api;
            let result = this.parseApi(url);
            result.option = result.option || "get";
            return result;
        },
        _apiUpdate() {
            let url = this.apiUpdate || this.api;
            let result = this.parseApi(url);
            if (/:id/.test(result.url)) {
                result.url = result.url.replace(/:id/, this.selectedItem._id);
            } else {
                result.url = result.url + "/" + this.selectedItem._id;
            }
            result.option = result.option || "put";
            return result;
        },
        _apiRemove() {
            let url = this.apiRemove || this.api;
            let result = this.parseApi(url);
            if (/:id/.test(result.url)) {
                result.url = result.url.replace(/:id/, this.selectedItem._id);
            } else {
                result.url = result.url + "/" + this.selectedItem._id;
            }
            result.option = result.option || "delete";
            return result;
        }
    },
    methods: {
        showDeleteConfirm(item) {
            if (!item) {
                return;
            }
            this.selectedItem = item;
            this.$refs.deleteConfirm.open(`Would you like to delete ${item.name ? item.name : ""}?`, null, item);
        },
        deleteConfirmed(close) {
            if (close == "ok") {
                this.deleteItem();
            }
        },
        handleSort({ name, type }) {
            if (type == "asc") {
                this.sort = name;
            } else {
                this.sort = "-" + name;
            }
            this.page.pageNo = 1;
            this.loadItems();
        },
        async deleteItem(item) {
            if (this.beforeDelete) {
                await this.beforeDelete(item, this.items, this.page);
            }
            let result = await this.$store.dispatch(`crud/${this._apiRemove.option}`, {
                api: this._apiRemove.url
            });
            this.loadItems();
            this.$emit("deleted", result);
            this.$store.commit("setMessage", "Delete Success");
        },
        async updateItem(patch) {
            this.selectedItem = patch;
            var result = await this.$store.dispatch(`crud/${this._apiUpdate.option}`, {
                api: this._apiUpdate.url,
                data: patch
            });
            // this.loadItems();
            this.$emit("updated", result);
            this.$store.commit("setMessage", "Update Success");
        },
        async loadItems(reload) {
            if (reload) {
                this.page.pageNo = 1;
            }
            let result = await this.$store.dispatch("crud/get", {
                api: this._apiList.url,
                params: {
                    q: this.search || undefined,
                    sort: this.sort || undefined,
                    ...this.params,
                    ...this.page
                }
            });
            if (result.data && result.page) {
                result.data.forEach(d => (d.expand = this.defaultExpand));
                this.items = result.data;
                this.page = result.page;
            } else {
                if (this.search) {
                    var reg = new RegExp((this.search || "").trim(), "i");
                    result = result.filter(r => {
                        let pass = false;
                        pass = pass || this.usedfields.find(field => reg.test(this.getModel(r, field.data)));
                        return pass;
                    });
                }
                this.items = result;
                this.page = { total: 1, pageNo: 1, pageSize: result.length };
            }
        },
        onPagination({ page, size }) {
            this.page.pageNo = page;
            this.page.pageSize = size;
            this.loadItems();
        },
        setItemExpand(item) {
            this.$set(item, "expand", !item.expand);
        }
    },
    async mounted() {
        await this.loadItems();
    }
};
</script>
<style lang="less">
.common-list {
    .list-toolbar {
        display: flex;
        align-items: center;
        padding: 0 20px;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .title-select {
        display: flex;
        align-items: center;
        margin-right: 10px;

        label {
            margin-right: 10px;
            color: #aaa;
        }
        .md-select {
            min-width: auto;
            line-height: 20px;
        }
        .val {
            line-height: 31px;
        }
    }
    .md-table {
        table {
            display: block;
            min-width: 1200px;
        }
        .md-table-header {
            display: block;
            background: #eee;
        }
        .md-table-body {
            display: block;
        }

        .md-table-row {
            height: auto;
            min-height: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            white-space: nowrap;
            &:hover {
                background: #eee;
                td {
                    background-color: transparent !important;
                }
            }
            .md-table-head {
                height: auto;
                min-height: 50px;
                .md-table-head-container {
                    height: auto;
                }
            }
            .md-table-cell {
                height: auto;
                min-height: 50px;
                .md-table-cell-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }
            }
            td,
            th {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 120px;
                img {
                    max-height: 40px;
                }
            }

            &.sub-table-row {
                padding: 30px;
                height: auto;
                justify-content: flex-start;

                &:hover {
                    background: inherit;
                }
                .md-table {
                    flex: 1;

                    .md-table-header {
                        background: #f7f7f7;
                        tr {
                            background: #f7f7f7;
                        }

                        .md-table-head-container {
                            height: 40px;
                            padding: 7px 0;
                        }
                    }
                    .md-table-body {
                        background: #f7f7f7;
                    }
                }
            }

            &.md-selected {
                .md-table-cell {
                    background-color: inherit;
                }
            }
        }
    }
}
</style>
