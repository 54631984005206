<template>
    <div class="list-field" v-if="model">
        <!--avatar-->
        <template v-if="f.data == 'avatar'">
            <img :src="(model.avatar || getDefaultHead(model.name)) | googleimage" alt />
        </template>

        <!--image-->
        <template v-else-if="ftype == 'image'">
            <img :class="{ round: f.round }" :src="getModel(model, f.data) | googleimage" alt />
        </template>

        <!--currency-->
        <template v-else-if="ftype == 'currency'">{{ getModel(model, f.data) | currency }}</template>

        <!--array-join-->
        <template v-else-if="ftype == 'array-join'">{{
            getModel(model, f.data)
                .map(i => i[f.key])
                .join(" | ")
        }}</template>

        <!--category-->
        <template v-else-if="ftype == 'category'">{{ getCategory(getModel(model, f.data)) }}</template>

        <!--editor-->
        <template v-else-if="ftype == 'editor'">{{ editorValue }}</template>

        <!--link-->
        <template v-else-if="ftype == 'link'">
            <router-link :to="parseLink(model, f.link)" v-if="!/http/i.test(getModel(model, f.data))">{{ getModel(model, f.data) || f.default }}</router-link>
            <a :href="parseLink(model, f.link)" target="_blank" v-else>{{ getModel(model, f.data) || f.default }}</a>
        </template>

        <!--date-->
        <template v-else-if="ftype == 'datetime'">{{ getModel(model, f.data) | date }}</template>

        <!--boolean-->
        <template v-else-if="ftype == 'boolean'">
            <md-switch :disabled="!f.action" @change="updateItem(model)" v-model="model[f.data]"></md-switch>
        </template>

        <!--boolean-->
        <template v-else-if="ftype == 'yesno'">
            {{ getModel(model, f.data) ? "Yes" : "No" }}
        </template>

        <!--boolean-->
        <template v-else-if="ftype == 'val' && f.val && typeof f.val == 'function'">
            {{ f.val(model) }}
        </template>

        <!--else-->
        <template v-else>{{ getModel(model, f.data) }}</template>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    name: "list-f",
    props: {
        f: Object,
        model: Object
    },
    computed: {
        ...mapGetters({
            getModel: "getModel",
            parseLink: "parseLink",
            getDefaultHead: "user/getDefaultHead",
            categories: "category/categories"
        }),
        getCategory() {
            return _id => {
                if (!_id) {
                    return "";
                }
                let category = this.categories.find(c => c._id == _id);
                if (category) {
                    let ls = {
                        l1: "",
                        l2: "",
                        l3: ""
                    };
                    ls[`l${category.level}`] = category;
                    ls[`l${category.level - 1}`] = this.categories.find(c => category.parent && c._id == category.parent._id);
                    ls[`l${category.level - 2}`] = this.categories.find(c => ls[`l${category.level - 1}`] && ls[`l${category.level - 1}`].parent && c._id == ls[`l${category.level - 1}`].parent._id);
                    return [ls[`l1`], ls[`l2`], ls[`l3`]]
                        .filter(a => a)
                        .map(a => a.name)
                        .join(" - ");
                }
                return "";
            };
        },
        ftype() {
            if (typeof this.f.type == "function") {
                return this.f.type(this.model);
            }
            return this.f.type;
        },
        editorValue() {
            let html = this.getModel(this.model, this.f.data) || this.f.default;
            if (html) {
                let dom = document.createElement("div");
                dom.innerHTML = html;
                return dom.innerText.substring(0, 20) + "...";
            }
            return "";
        }
    },
    methods: {
        updateItem(patch) {
            this.$emit("update", patch);
        }
    },
    async created() {
        if (this.f.type == "category") {
            await this.$store.dispatch("category/loadCategories");
        }
    }
};
</script>
<style lang="less">
.list-field {
    white-space: initial;
    text-align: center;
    .md-switch {
        margin: 0;
    }
}
</style>
