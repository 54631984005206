<template>
    <md-dialog ref="dialog" class="ReviewReplyModal" @close="handleClose">
        <form novalidate @submit.prevent="submit">
            <md-card>
                <md-card-header>
                    <h2 style="margin:0">Reply Review</h2>
                </md-card-header>
                <md-card-content>
                    <md-input-container :class="{ 'md-input-invalid': errors.has('message') }">
                        <label>Reply Content</label>
                        <md-textarea rows="8" v-model="message" name="message" v-validate="'required'" style="height:178px;"></md-textarea>
                        <span class="md-error" v-show="errors.has('message')">{{ errors.first("message") }}</span>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <md-button type="submit">Submit</md-button>
                </md-card-actions>
            </md-card>
        </form>
    </md-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { reviewSettings } from "@/config";

export default {
    $_veeValidate: {
        validator: "new" // give me my own validator scope.
    },
    data() {
        return {
            reviewId: "",

            message: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasRole: "user/hasRole"
        })
    },
    methods: {
        buildDTD() {
            let dtd = {
                promise: null,
                resolve: null,
                reject: null
            };

            dtd.promise = new Promise((resolve, reject) => {
                dtd.resolve = resolve;
                dtd.reject = reject;
            });
            return dtd;
        },
        async process(reviewId) {
            this.dtd = this.buildDTD();
            this.reviewId = reviewId;
            this.message = "";
            this.$validator.reset();

            this.$refs["dialog"].open();
            let result = await this.dtd.promise;
            this.$refs["dialog"].close();
            return result;
        },
        handleClose() {
            this.dtd && this.dtd.reject({ action: "close" });
        },
        async submit() {
            const valid = await this.$validator.validateAll();
            if (!valid) {
                this.$store.commit("setMessage", "Please Check Form Validation");
                throw new Error("Validate Error");
            }

            await this.$store.dispatch("crud/post", {
                api: "reviews",
                data: {
                    user_id: this.user._id,
                    user_name: this.user.name,
                    user_email: this.user.email,
                    mainId: this.reviewId,
                    message: this.message
                }
            });

            if (reviewSettings.moderate) {
                this.$store.commit("setMessage", "Your review is under moderation. Will be visible to public after approval.");
            } else {
                this.$store.commit("setMessage", "Review Submitted.");
            }
            this.dtd && this.dtd.resolve({ action: "success" });
        }
    }
};
</script>
